import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/heroImage"
import Product from "../components/product"

const Sortiment = () => {
  const data = useStaticQuery(graphql`
    query {
      index1: file(relativePath: { eq: "sortiment-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index2: file(relativePath: { eq: "sortiment-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index3: file(relativePath: { eq: "sortiment-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index4: file(relativePath: { eq: "sortiment-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index5: file(relativePath: { eq: "sortiment-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index6: file(relativePath: { eq: "sortiment-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index7: file(relativePath: { eq: "sortiment-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index8: file(relativePath: { eq: "sortiment-8.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index9: file(relativePath: { eq: "sortiment-9.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index10: file(relativePath: { eq: "sortiment-10.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index11: file(relativePath: { eq: "sortiment-11.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const products = [
    {
      "title": "Föhrer Weisbrot.",
      "description": "Föhr ist nicht nur das Sylt für Bodenständige, sondern auch die Heimat von Volker. Er ist der Chef von Bäcker „Hansen“ und bis auf seine blonden Haare ein vollkommen untypischer Friese: Schmal, kein Bart und meist nen roten Kopf. <br /> <br /> Und genauso wenig ist das Föhrer ein normales Weißbrot. Kross gebacken, mit nem kleinen Grieß intus und ein bisschen mehr als nur Luft in der Krume. Ganz eindeutig: Beide sind mit ihren Eigenschaften einfach einzigartig.",
      "ingredients": ["Weizenmehl", "Wasser", "Hartweisen Grieß", "Salz", "Rapsöl", "Hefe"]
    },
    {
      "title": "Johnny.",
      "description": "„John Baker“ in Zürich war für mich ein echter Glücksfall. Endlich mal einer, der das Backhandwerk richtig geil aufzieht und auf die überflüssigen Zusatzstoffe verzichtet. <br /><br />„Eher hacke ich mir die Hände ab“ sagte John einst zu mir. – klare Ansage. John selbst ist ein eher bescheidener und ruhiger Typ. „Nur nid jufle“ lautet seine Devise. Ganz anders als sein Hausbrot Schweizer Ruchmehl, denn die Kruste kracht mal ordentlich. Und wer hat’s erfunden?",
      "ingredients": ["Ruchmehl", "Wasser", "Roggenvollkornmehl", "Salz", "Hefe"]
    },
    {
      "title": "Heinz.",
      "description": "Die Kruste fast schwarz, ein heller Boden, eine saftig würzige Krume. Dieses Trio hat für meinen Vater, meinen Bruder und mich im Jahr 2015 den Unter- schied gemacht: Wir wurden im Finale der ZDF-Sendung „Deutschlands bester Bäcker“ als Sieger ausgezeichnet. <br /> <br />Und der Erfahrenste in der Runde hat genau auf dieses Brot gesetzt. Nichts Abgefahrenes, keine hippen Zutaten, keine Teigruhe über viele Tage. Ein Brot mit Charakter, so wie früher. Und das auf handwerklich ganz hohem Niveau. So wie mein Vater eben.",
      "ingredients": ["Roggenvollkornmehl", "Roggenvollkorn", "Sauerteig", "Wasser", "Weizenmehl", "Aromabrot", "Salz", "Brotgewürze", "Hefe"]
    },
    {
      "title": "Aschauer.",
      "description": "„Aschau ist ein dunkler Ort.“ Dieser Satz bleibt mir in Verbindung mit dem Bäcker „Greimel“ aus Aschau am Inn für immer in Erinnerung. Man kommt im Dunkeln und man geht im Dunkeln – das war die Devise für die ersten Wochen nach der Eröffnung seiner Bäckerei. <br /> <br /> Für das unverwechselbare Farbenspiel des Aschauers sorgt die Teigruhe über Nacht – genauso wie für seine ungleichmäßige Porung. Drei verschiedene Mehlsorten verleihen diesem Brot seinen harakteristischen Geschmack. Ein Bäcker wie der Greimel und ein Brot wie das Aschauer bringen wieder Licht in die Bäckerwelt.",
      "ingredients": ["Wasser", "Weizenmehl", "Dinkelmehl", "Roggenvollkornmehl", "Roggenvollkorn", "Sauerteig", "Aromabrot", "Salz"]
    },
    {
      "title": "Bonette.",
      "description": "Dieses Brot ist „made im Schwabenländle“ und damit das beste Beispiel dafür, wie wertvoll die Vernetzung im Bäckerhandwerk ist – vier Bäcker, ein Brot. Die Idee für dieses Brot entstand in meiner Zeit in Stuttgart beim Italiener-Duo von „Königsbäck“. Dort wurde es als Ciabatta ins Leben gerufen. <br /> <br />In Herrenberg wurde es dann beim Bäcker „Baier“ zum Bonnette geschliffen. Jaja, das Ländle und seine Laible… eine ungleichmäßige Porung samt zarter Kruste – dem Rheinländer sei Dank!",
      "ingredients": ["Weizenmehl", "Wasser", "Roggenvollkornmehl", "Zuckerrübensirup", "Salz", "Hefe"]
    },
    {
      "title": "Roggen Vollkorn.",
      "description": "Einfach ein Klassiker. Dafür, dass es nicht von spannenden Orten kommt und keine besondere Geschichte hat, beweist dieses Brot ganz schön viel Vielfalt. 100% Vollkorn mit gekochten Körnern inside. <br /> <br />Ein Brot, das alles mitbringt, was man eben so braucht. Genau wie die Stadt, in der es gebacken wird.",
      "ingredients": ["Roggenvollkornmehl", "Roggenvollkorn", "Sauerteig", "Wasser", "Roggenkörner gekocht", "Zuckerrübensirup", "Salz"]
    },
    {
      "title": "Volle Hütte Dinkel.",
      "description": "Der Name ist Programm: Dinkel bis unters Dach. Die Basis kommt aus Lahnstein, meiner schönen Heimat. <br /> <br />Dort, wo alles begann und die #roadtobakery ins Leben gerufen wurde. Dort ist es nicht zu 100% Dinkelvollkorn. Daher war klar: alles oder nichts. Gekochte Dinkelkörner und feine Flocken oben drauf. <br /> <br /> <br />Gib ihm! Nimm dir!",
      "ingredients": ["Dinkelvollkornmehl", "Dinkelmehl", "Wasser", "Dinkel Sauerteig", "Dinkelkörner gekocht", "Salz", "Zuckerrübensirup", "Hefe"]
    },
    {
      "title": "Kleines Schwarzes.",
      "description": "Nicht vorstellbar wie es wäre, wenn Bonn den guten Ludwig van Beethoven nicht hätte. Ähnlich ist das ganze beim Schwarzbrot, ohne gehts, macht aber wenig Sinn. Es ist quasi unser Französisches Baguette, unsere Italienische Pannetone, unser Englischer Toast. <br /> <br />100% Roggenvollkornschrot, 4 Stunden Backzeit und mit 3000g der schwerste in der Runde und als Scheibe eines der feinsten. Ein Brot, welches hier hin gehört. Wie der gute Ludwig van eben.",
      "ingredients": ["Roggenkörner gekocht", "Wasser", "Roggenvollkornschrot", "Roggenvollkorn", "Sauerteig", "Sonnenblumenkerne", "Leinsaat", "Zuckerrübensirup", "Salz", "Hefe"]
    },
    {
      "title": "Weizen Vollkorn.",
      "description": "Wenn ich früher an Vollkorn dachte, hatte ich nur Folgendes im Sinn: kompakt, trocken, fertig.<br /> <br/> Und heute? <br /> <br />Beweist unser Weizen-Vollkorn das Gegenteil. Drei Saaten im und ums Brot geben dem mit 90% Vollkornmehl gebackenen Brot seinen unvergleichlichen Geschmack. Kräftig im Ergebnis kann das ein oder andere Korn schon mal leicht bitter sein. <br/>Das soll so!",
      "ingredients": ["Weizenvollkornmehl", "Wasser", "Weizenvollkorn", "Sauerteig", "Ruchmehl", "Sonnenblumenkerne", "Kürbiskerne", "Leinsaat", "Salz"]
    },
    {
      "title": "Die Nr. 10.",
      "description": "Früher sagte man „Tagesbrot“, heute sagen wir Special One. Mit vielen Körnern oder ganz ohne, mit Dinkel, Roggen oder beidem. <br /> <br /> Die Nummer 10 ist das Brot, welches sich von Dienstag bis Samstag jeden Tag ändert und in der nächsten Woche auf’s neue in der gleichen Reihen- folge startet. Es bleibt also abwechslungsreich.",
      "ingredients": ["Weizenvollkornmehl", "Wasser", "Weizenvollkorn", "Sauerteig", "Ruchmehl", "Sonnenblumenkerne", "Kürbiskerne", "Leinsaat", "Salz"],
      "information": "(Immer Mittwochs: Nuss-Johnny)"
    },
  ]


  return (
    <Layout page={"sortiment"}>
      <SEO title="Sortiment"/>
      <HeroImage source={data.index1.childImageSharp.fluid}/>
      <div className={"wrapper"}>
        <div data-srgrid-row="">
          <div data-srgrid-col="xs-13 m-24">
            <h1 className={"h1--max"}>
              Unser Sortiment.
            </h1>
          </div>
        </div>
        <Product key={products[0].title} product={products[0]} image={data.index2.childImageSharp.fluid}/>
        <Product key={products[1].title} product={products[1]} image={data.index3.childImageSharp.fluid}/>
        <Product key={products[2].title} product={products[2]} image={data.index4.childImageSharp.fluid}/>
        <Product key={products[3].title} product={products[3]} image={data.index5.childImageSharp.fluid}/>
        <Product key={products[4].title} product={products[4]} image={data.index6.childImageSharp.fluid}/>
        <Product key={products[5].title} product={products[5]} image={data.index7.childImageSharp.fluid}/>
        <Product key={products[6].title} product={products[6]} image={data.index8.childImageSharp.fluid}/>
        <Product key={products[7].title} product={products[7]} image={data.index9.childImageSharp.fluid}/>
        <Product key={products[8].title} product={products[8]} image={data.index10.childImageSharp.fluid}/>
        <Product key={products[9].title} product={products[9]} image={data.index11.childImageSharp.fluid} className={'last-item'}/>
      </div>
    </Layout>
  )
}


export default Sortiment
