import React from "react"

const ListElement = ({ list }) => (
  <ul className={"list"}>
    {list.map(item => (
      <li key={item} className={"fira-mono fira-mono--s list__item"}>
        {item}
      </li>
    ))}
  </ul>
)

export default ListElement