import React from "react"
import Img from "gatsby-image"
import ListElement from "./ListElement"
import { withBreakpoints } from "react-breakpoints"

const Product = ({ product, image, breakpoints, currentBreakpoint, className }) => (
  <div className={`product ${className ? className : ""}`}>
    {breakpoints[currentBreakpoint] >= breakpoints.m ? (
      <div data-srgrid-row="" className={"product__title"}>
        <div data-srgrid-col="xs-24 m-8 m-offset-12 l-6 l-offset-13 xl-5 xl-offset-14">
          <p className={"h1--max product__title"}>{product.title}</p>
        </div>
      </div>
    ) : null}
    <div data-srgrid-row="">
      <div data-srgrid-col="xs-24 m-12 l-13 xl-14">
        <Img fluid={image} alt={product.title}/>
        {product.information !== undefined && (
          <div data-srgrid-row="">
            <div data-srgrid-col="xs-12 m-8">
              <p className={"p"}>{product.information}</p>
            </div>
          </div>
        )}
      </div>
      {breakpoints[currentBreakpoint] < breakpoints.m ? (
        <div data-srgrid-col="xs-12">
          <p className={"h1--max product__title"}>{product.title}</p>
        </div>
      ) : null}
      <div data-srgrid-col="xs-24 m-12 l-6 xl-5">
        <p dangerouslySetInnerHTML={{ __html: product.description }}/>
      </div>
      <div data-srgrid-col="xs-24 m-12 m-offset-12 l-4 l-offset-1 xl-4">
        <ListElement list={product.ingredients}/>
      </div>
    </div>
  </div>
)

export default withBreakpoints(Product)